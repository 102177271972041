<template functionnal>
  <div class="template mb-4">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos formations | <span class="marker-effect">Cours de Français</span></h1>
          <div class="divider"></div>
          Toutes nos formations s’articulent autour des exigences linguistiques du Cadre Européen
          Commun de Référence pour les Langues CECRL. <br />
          Elles adoptent une approche multiculturelle à visée sociale et communicative. <br />
          L’intitulé des cours indique le niveau à atteindre à l’issu de la formation. <br />
          Tous nos cours généraux permettent de s’inscrire à l’examen du DELF en fin d’année, les
          cours à visée professionnelle proposent en plus du DELF le passage du DCL (RS 5454,
          certificateur ministère de l'éducation nationale, date d'enregistrement 17/06/2021. <a
            href="https://www.francecompetences.fr/recherche/rs/5454/"
            target="_blank"
            >Pour plus d'information, cliquez ici</a
          >). <br />
          <div class="divider"></div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="row"><h5>Français langue étrangère</h5></div>
            <div class="row mt-3">
              <div class="font-bold">Public et prérequis</div>
              Adultes migrants scolarisés pendant au moins 5 ans dans le pays d’origine, lecteur et
              scripteur d’une langue utilisant l’alphabet latin, &lt; 5 ans de présence en France
              <br />
            </div>

            <div class="row">
              <div class="font-bold">Objectifs</div>
              Développer l'autonomie sociale et donner les outils linguistiques et culturels pour
              une meilleure insertion dans la société française.<br />
              Sensibiliser à l'expression culturelle par des sorties autour de thématiques diverses
              et en réaliser un livret photos/textes témoins. <br />
              Contribuer au mieux vivre ensemble. <br />
              Possibilité de préparation et d’inscription au DELF A1 ou A2. <br />
              Modalités d&#39;évaluation : test de positionnement, évaluations intermédiaires avec
              des examens blancs du DELF et évaluation finale.
            </div>

            <div class="row">
              <div class="font-bold">Supports pédagogiques</div>
              Manuels FLE, ressources numériques, travail en groupe sur les thématiques de la vie
              courante.
            </div>

            <div class="col p-0">
              <div class="font-bold">Modalités d'évaluation</div>
              Test de positionnement interne ou externe, évaluations intermédiaires avec des examens
              blancs (DELF A1 ou A2) et évaluation finale.
            </div>

            <div class="col p-0">
              <div class="font-bold">Taux de réussite</div>
              En juin 2024, 81% des élèves ont réussi le DELF (A1/A2).
            </div>

            <div class="col p-0">
              <div class="font-bold">Taux de satisfaction</div>
              Taux de satisfaction de 94% sur la qualité de la formation
            </div>

            <div class="row mt-10">
              <img src="@/assets/images/02-CoursFrancais.jpeg" alt="cours de francais" />
            </div>

            <div class="row mt-5">
              <h6>Descriptions des cours</h6>

              <div class="my-3">
                <b class="niveau-title">Niveau Pré A1 (anciennement A1.1)</b>
                <ul>
                  <li>
                    <span class="font-semibold">Horaires:</span> Lundi et jeudi de 14h à 16h30
                  </li>
                  <li>
                    <span class="font-semibold">Durée, volume horaire:</span> D’octobre à juin, soit
                    150 heures
                  </li>
                  <li>
                    <span class="font-semibold">Tarif:</span> 100 Euros (possibilité de payer en 2
                    ou 3 fois)
                  </li>
                  <li><span class="font-semibold">Lieu de cours:</span> Femmes Initiatives</li>
                </ul>
              </div>

              <div class="my-3">
                <b class="niveau-title">Niveau A1</b>
                <ul>
                  <li>
                    <span class="font-semibold">Horaires:</span> Mardi et vendredi de 9h à 11h30
                  </li>
                  <li>
                    <span class="font-semibold">Durée, volume horaire:</span> D’octobre à juin, soit
                    150 heures
                  </li>
                  <li>
                    <span class="font-semibold">Tarif:</span> 100 Euros (possibilité de payer en 2
                    ou 3 fois)
                  </li>
                  <li><span class="font-semibold">Lieu de cours:</span> Femmes Initiatives</li>
                </ul>
              </div>

              <div class="my-3">
                <b class="niveau-title">Niveau A2</b>
                <ul>
                  <li>
                    <span class="font-semibold">Horaires:</span> Mardi et jeudi de 13h30 à 16h00
                  </li>
                  <li>
                    <span class="font-semibold">Durée, volume horaire:</span> D’octobre à juin, soit
                    150 heures
                  </li>
                  <li>
                    <span class="font-semibold">Tarif:</span> 100 Euros (possibilité de payer en 2
                    ou 3 fois)
                  </li>
                  <li>
                    <span class="font-semibold">Lieu de cours:</span> Centre social CAF Toussarégo
                    au 21 Avenue Claude Régaud, 75013 Paris
                  </li>
                </ul>
              </div>

              <div>
                <a href="/asl_fle.pdf" download>Télécharger la plaquette</a>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row">
              <h5>
                Français langue étrangère <br />
                à visée professionnelle
              </h5>
            </div>
            <div class="row">
              <div class="font-bold">Public et prérequis</div>
              Public FLE avec un projet professionnel ou ayant pour objectif une insertion
              professionnelle en France et ayant moins de 5 ans de présence en France.<br />
            </div>
            <div class="row">
              <div class="font-bold">Objectifs</div>
              Donner au public en insertion ou en démarche d'insertion les compétences langagières
              et les outils indispensables pour l'insertion dans une entreprise française.<br />
              Développer le savoir-faire et le savoir-être propres au milieu professionnel. <br />
              Préparer à la certification du DELF A2/B1/B2 ou du DCL.
            </div>

            <div class="row">
              <div class="font-bold">Supports pédagogiques</div>
              Manuels FLE professionnel, documents authentiques, ressources numériques.
            </div>

            <div class="row">
              <div class="font-bold">Modalités d'évaluation</div>
              Test de positionnement interne ou externe, évaluations intermédiaires avec des examens
              blancs (DELF A2/B1/B2 ou DCL) et évaluation finale.
            </div>

            <div class="col p-0">
              <div class="font-bold">Taux de réussite</div>
              En juin 2024, 80% des élèves ont réussi le DELF (A2/B1/B2), et 100% pour le DCL.
            </div>

            <div class="col p-0">
              <div class="font-bold">Taux de satisfaction</div>
              Taux de satisfaction de 90% sur la qualité de la formation
            </div>

            <div class="row mt-20">
              <img
                style="margin-top: 4px;"
                src="@/assets/images/03-vise-pro.jpeg"
                alt="cour de francais visee pro"
              />
            </div>

            <div class="row mt-4">
              <h6>Descriptions des cours</h6>

              <div class="my-3">
                <b class="niveau-title">Niveau A2</b>
                <ul>
                  <li>
                    <span class="font-semibold">Horaire:</span> Mardi et jeudi de 9h à 12h en
                    présentiel
                  </li>
                  <li>
                    <span class="font-semibold">Durée, volume horaire:</span> D’octobre à juin, soit
                    180 heures
                  </li>
                  <li><span class="font-semibold">Tarif:</span> 40 Euros</li>
                  <li><span class="font-semibold">Lieu de cours:</span> Femmes Initiatives</li>
                </ul>
              </div>

              <div class="my-3">
                <b class="niveau-title">Niveau B1/B2</b>
                <ul clas>
                  <li>
                    <span class="font-semibold">Horaire:</span> Lundi ou jeudi de 9h à 12h en
                    présentiel et mercredi de 9h à 11h en distanciel. Tutorat individuel régulier
                    sur Zoom
                  </li>
                  <li>
                    <span class="font-semibold">Durée, volume horaire:</span> D’octobre à juin, soit
                    180 heures
                  </li>
                  <li><span class="font-semibold">Tarif:</span> 40 Euros</li>
                  <li><span class="font-semibold">Lieu de cours:</span> Femmes Initiatives</li>
                </ul>
              </div>

              <div>
                <a href="/fle_visee_pro.pdf" download>Télécharger la plaquette</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cta mt-8">
        <button class="btn-primary btn-xlarge">
          <router-link class="white" to="/inscription" target="_blank"
            >Modalités d'inscription</router-link
          >
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.niveau-title {
  color: #e94218;
  margin-top: 30px;
  font-size: 1.25rem;
}
</style>
